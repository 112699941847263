<template>
  <div>
    <FormazionePersona :persona="persona" />
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FormazionePersona from "@/views/persone/dettaglio-persona/FormazionePersona.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

export default defineComponent({
  name: "anagrafiche-insegnante",
  components: { FormazionePersona },
  props: {
    persona: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      id_persona,
      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
    };
  },
});
</script>
